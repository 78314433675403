import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/default.js";
import { Link } from 'gatsby';
import { Svg } from "../components/icon";
import Logo from "../icons/logo.svg";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Link to="/programming" className="text-yellow-900" mdxType="Link">
	<div className="flex justify-center items-center max-w-sm p-8 rounded shadow" style={{
        background: '#f7df1f'
      }}>
		<span className="inline-block w-16 h-16 mr-4">
			<img src="https://upload.wikimedia.org/wikipedia/commons/9/99/Unofficial_JavaScript_logo_2.svg" />
		</span>
		Javascript Programming Resources
	</div>
    </Link>
    <br />
    <Link to="/javascript" className="text-green-100" mdxType="Link">
	<div className="flex justify-center items-center bg-green-800 max-w-sm p-8 rounded shadow">
		<span className="inline-block w-12 h-12 mr-4">
			<Svg icon={Logo} mdxType="Svg" />
		</span>
		Coding Practices used in Trailguide
	</div>
    </Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      